import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AppHeader from 'tio-employee/components/app/header';
import AuthRegisterPostAuthTermsDialog from 'tio-employee/components/auth/register/post-auth-terms-dialog';
import AuthSessionExpiringDialog from 'tio-employee/components/auth/session-expiring-dialog';
import Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
import type EmployeeService from 'tio-employee/services/employee';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type UserModel from 'tio-common/models/user';
import AcademicCap from 'ember-static-heroicons/components/outline-24/academic-cap';
import Home from 'ember-static-heroicons/components/outline-24/home';
import UserCircle from 'ember-static-heroicons/components/outline-24/user-circle';
import Lifebuoy from 'ember-static-heroicons/components/outline-24/lifebuoy';
import RocketLaunch from 'ember-static-heroicons/components/outline-24/rocket-launch';
import CurrencyDollar from 'ember-static-heroicons/components/outline-24/currency-dollar';
import BookOpen from 'ember-static-heroicons/components/outline-24/book-open';
import XMark from 'ember-static-heroicons/components/outline-24/x-mark';
import ArrowLeftOnRectangle from 'ember-static-heroicons/components/outline-24/arrow-left-on-rectangle';
import { and, or } from 'tio-ui/utilities';
import type FeaturesService from 'tio-common/services/features';
import Newspaper from 'ember-static-heroicons/components/outline-24/newspaper';
import TableCells from 'ember-static-heroicons/components/outline-24/table-cells';
import ArrowUpTray from 'ember-static-heroicons/components/outline-24/arrow-up-tray';
import type SideDrawerService from 'tio-common/services/side-drawer';
import type Owner from '@ember/owner';
import { Divider } from 'tio-ui/components/utilities';
import { Sidebar, SidebarItem, SidebarItemGroup } from 'tio-common/components/sidebar';

interface AuthentecatedRouteSignature {
  Args: {
    model: UserModel;
  };
  Blocks: {
    default: [];
  };
}

export default class AuthenticatedRouteComponent extends Component<AuthentecatedRouteSignature> {
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;
  @service declare partner: PartnerService;
  @service declare employee: EmployeeService;
  @service declare features: FeaturesService;
  @service declare sideDrawer: SideDrawerService;

  @tracked isSidebarOpen = true;
  @tracked isFinishedRegisterTermsDialog = false;

  constructor(owner: Owner, args: AuthentecatedRouteSignature['Args']) {
    super(owner, args);
    this.sideDrawer.isOpen = true;
  }

  get isSidebarOpenHack() {
    // Bridge until old one is gone.
    // This is how the hamburger stack is able to open the sidebar.
    return this.sideDrawer.isOpen;
  }

  get taPartnerPaymentsReviewPageEnabled() {
    return this.features.isEnabled('taPartnerPaymentsReviewPage');
  }

  get isTaOnly() {
    return this.partner.isTaOnly || this.employee.isTaOnlyEmployee;
  }

  get hasTuitionAssistance() {
    return !!(this.partner.hasNewTuitionAssistance || this.employee.hasLegacyTuitionAssistance);
  }

  get isOwner() {
    return !!this.sessionContext.roles.find((e) => e.relationshipType === 'ACCOUNT_OWNER');
  }

  get isAdmin() {
    return !!this.sessionContext.roles.find((e) => e.relationshipType === 'TIO_ADMIN');
  }

  get isParticipant() {
    return !!(
      this.sessionContext.roles.find((e) => e.relationshipType === 'PARTICIPANT') && !this.isTaOnly
    );
  }

  get isEmployeeAdmin() {
    return !!this.sessionContext.roles.find((e) => e.relationshipType === 'EMPLOYEE_ADMIN');
  }

  get isPslfAdmin() {
    return !!this.sessionContext.roles.find((e) => e.relationshipType === 'PSLF_ADMIN');
  }

  get isPslfEnabled() {
    return !!this.partner.isPslfEnabled;
  }

  get isSyfEnabled() {
    return !!this.partner.isSyfEnabled;
  }

  get idrEnabled() {
    return this.partner.isIdrEnabled;
  }

  get isTasApprover() {
    return !!this.sessionContext.roles.find(
      (e) => e.relationshipType === 'TAS.Approver.SUPERVISOR'
    );
  }

  get isTaEnabledSettingExists() {
    return this.partner.companySettings.tuitionAssistance &&
      'isTaEnabled' in this.partner.companySettings.tuitionAssistance
      ? true
      : false;
  }

  get isTaSettingEnabled() {
    return (
      this.partner.companySettings.tuitionAssistance &&
      !!this.partner.companySettings.tuitionAssistance.isTaEnabled
    );
  }

  get showTuitionAssistance() {
    if (this.isTaEnabledSettingExists) {
      return !!(this.partner.hasNewTuitionAssistance && this.isTaSettingEnabled);
    } else {
      return this.hasTuitionAssistance;
    }
  }

  get showRegisterTermsDialog() {
    // Use a tracked property to allow for faster closing of the dialog
    // (i.e. better UX) before the sessionContext re-calcs `needsToAcceptTerms`
    if (this.isFinishedRegisterTermsDialog) {
      return false;
    }
    return this.sessionContext.needsToAcceptAppTerms;
  }

  get showSlr() {
    // check for contribution plan
    return this.employee.current.plan && this.employee.current.plan.cadence !== 'NEVER';
  }

  @action
  toggleSidebar() {
    this.sideDrawer.toggleDrawer();
  }

  @action
  async didCompleteSsoRegistration() {
    this.isFinishedRegisterTermsDialog = true;
    // Reload the user with the newly created usage agreement and privacy agreement
    await this.store.findRecord('user', this.sessionContext.user.id, {
      include: 'usage-agreement,privacy-agreement,custom-register-agreements',
      reload: true,
    });
  }

  <template>
    <div class="min-h-screen antialiased bg-gray-50 text-black">
      {{! Most examples use display none with a time, Removing pointer events
          allows css to just do its thing.
      }}
      <div
        class="relative z-50 lg:hidden {{unless this.isSidebarOpenHack 'pointer-events-none'}} "
        role="dialog"
        aria-modal="true"
      >
        <div
          class="fixed inset-0 transition-opacity ease-linear duration-300 bg-black/20
            {{if this.isSidebarOpenHack 'opacity-100' 'opacity-0'}}
            "
        ></div>

        <div class="fixed inset-0 flex">
          <div
            class="relative mr-16 flex w-full max-w-xs flex-1 transition-transform ease-in-out duration-300
              {{if this.isSidebarOpenHack 'translate-x-0' '-translate-x-full'}}
              "
          >
            <div
              class="absolute left-full top-0 flex w-8 h-16 bg-midnight rounded-r-md mt-5 transition-opacity ease-linear duration-300
                {{if this.isSidebarOpenHack 'opacity-100' 'opacity-0'}}
                "
            >
              <button type="button" class="w-8 h-16" {{on "click" this.toggleSidebar}}>
                <span class="sr-only">{{t "sidebar.close"}}</span>
                <XMark class="h-6 w-6 text-white" />
              </button>
            </div>

            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-midnight px-6 pb-4">
              <Sidebar>
                <SidebarContent
                  @isTaOnly={{this.isTaOnly}}
                  @showTuitionAssistance={{this.showTuitionAssistance}}
                  @isParticipant={{this.isParticipant}}
                  @showPslf={{this.isPslfEnabled}}
                  @showSyf={{this.isSyfEnabled}}
                  @showIdr={{this.idrEnabled}}
                  @isPslfAdmin={{this.isPslfAdmin}}
                  @isTasApprover={{this.isTasApprover}}
                  @isTasPartnerPaymentsEnabled={{this.taPartnerPaymentsReviewPageEnabled}}
                  @isEmployeeAdmin={{this.isEmployeeAdmin}}
                  @isAdmin={{this.isAdmin}}
                  @isOwner={{this.isOwner}}
                  @showSlr={{this.showSlr}}
                />
              </Sidebar>
            </div>
          </div>
        </div>
      </div>

      {{! Static sidebar for desktop }}
      <div class="hidden lg:fixed lg:inset-y-0 lg:z-5 lg:flex lg:w-56 lg:flex-col">
        <div
          class="flex grow flex-col gap-y-3 overflow-y-auto border-r border-gray-200 bg-midnight px-6 pb-4"
        >
          <Sidebar>
            <SidebarContent
              @isTaOnly={{this.isTaOnly}}
              @showTuitionAssistance={{this.showTuitionAssistance}}
              @isParticipant={{this.isParticipant}}
              @showPslf={{this.isPslfEnabled}}
              @showSyf={{this.isSyfEnabled}}
              @showIdr={{this.idrEnabled}}
              @isPslfAdmin={{this.isPslfAdmin}}
              @isTasApprover={{this.isTasApprover}}
              @isTasPartnerPaymentsEnabled={{this.taPartnerPaymentsReviewPageEnabled}}
              @isEmployeeAdmin={{this.isEmployeeAdmin}}
              @isAdmin={{this.isAdmin}}
              @isOwner={{this.isOwner}}
              @showSlr={{this.showSlr}}
            />
          </Sidebar>
        </div>
      </div>

      <div class="lg:pl-56">
        <AppHeader />
        <main class="scroll-smooth max-w-7xl">
          <div class="px-0">
            {{outlet}}
          </div>
        </main>
      </div>
    </div>

    {{! Showing status handled by dialog component itself }}
    <AuthSessionExpiringDialog />

    {{#if this.showRegisterTermsDialog}}
      <AuthRegisterPostAuthTermsDialog
        @didSave={{this.didCompleteSsoRegistration}}
        @primaryEmailAddress={{@model.primaryEmail}}
      />
    {{/if}}
  </template>
}

interface SidebarContentSignature {
  Args: {
    isTaOnly: boolean;
    showTuitionAssistance: boolean;
    isParticipant: boolean;
    showPslf: boolean;
    showSyf: boolean;
    showIdr: boolean;
    showSlr: boolean;
    isAdmin: boolean;
    isOwner: boolean;
    isPslfAdmin: boolean;
    isTasApprover: boolean;
    isTasPartnerPaymentsEnabled: boolean;
    isEmployeeAdmin: boolean;
  };
}

const SidebarContent: TOC<SidebarContentSignature> = <template>
  <li>
    <ul role="list" class="-mx-2 space-y-1">
      <SidebarItem @route="authenticated.dashboard" @icon={{Home}} @label="Home" />
      {{#if (or @isAdmin @isOwner)}}
        <SidebarItem
          @route="authenticated.admin.reports"
          @icon={{TableCells}}
          @label={{t "common.reporting.default"}}
          data-test-sidebar-admin-reports
        />
        <SidebarItem
          @route="authenticated.admin.employees"
          @icon={{UserCircle}}
          @label={{t "sidebar.admin_employees"}}
          data-test-sidebar-admin-employees
        />
        <SidebarItem
          @route="authenticated.admin.eligibility-files"
          @icon={{ArrowUpTray}}
          @label={{t "sidebar.eligibility_files"}}
          data-test-sidebar-admin-eligibility-files
        />
      {{/if}}
      {{#if (or @isAdmin (and @isOwner @showPslf) @isPslfAdmin)}}
        <SidebarItem
          @route="authenticated.admin.pslf.forms"
          @icon={{Newspaper}}
          @label={{t "sidebar.admin_pslf"}}
          data-test-sidebar-admin-pslf
        />
      {{/if}}
      {{#if (or @isAdmin @isOwner @isTasApprover)}}
        <SidebarItemGroup @icon={{UserCircle}} @label={{t "sidebar.admin_tuition_assistance"}}>
          {{#if (and @isTasPartnerPaymentsEnabled (or @isAdmin @isOwner @isEmployeeAdmin))}}
            <SidebarItem
              @route="authenticated.admin.tuition-assistance.payments"
              @label={{t "sidebar.payments"}}
              data-test-sidebar-admin-tuition-assistance-payments
            />
          {{/if}}
          <SidebarItem
            @route="authenticated.admin.tuition-assistance.applications.index"
            @label={{t "tuition_assistance.applications.default"}}
            data-test-sidebar-admin-tuition-assistance-applications
          />
          {{! TODO: Eventually this should be a single link that goes to reporting index page with links to each page }}
          {{#if (or @isAdmin @isOwner @isEmployeeAdmin)}}
            <SidebarItem
              @route="authenticated.admin.reporting.index"
              @label={{t "reports.all_reports_link"}}
              data-test-sidebar-admin-tuition-assistance-reports
            />
          {{/if}}
        </SidebarItemGroup>
      {{/if}}
      {{#if (or @isTasApprover @isPslfAdmin @isAdmin @isOwner)}}
        <li><Divider /></li>
      {{/if}}
      {{#if (and @isTaOnly @showTuitionAssistance)}}
        <SidebarItem
          @route="authenticated.tas.dashboard"
          @icon={{AcademicCap}}
          @label="Tuition Assistance"
          data-test-sidebar-employee-tuition-assistance-dashboard
        />
      {{/if}}

      {{#if @isParticipant}}
        {{#if @showSlr}}
          <SidebarItemGroup @icon={{UserCircle}} @label="Account Activity">
            <SidebarItem
              @route="authenticated.account-activity.accounts"
              @label="Loan Summary"
              data-test-sidebar-employee-student-loan-summary
            />
            <SidebarItem
              @route="authenticated.slr.dashboard"
              @label="Employer Contributions"
              data-test-sidebar-employee-employer-contributions
            />
          </SidebarItemGroup>
        {{else}}
          <SidebarItem
            @route="authenticated.account-activity.accounts"
            @icon={{UserCircle}}
            @label="Loan Summary"
            data-test-sidebar-employee-student-loan-summary
          />
        {{/if}}
        {{#if @showPslf}}
          <SidebarItemGroup @icon={{Lifebuoy}} @label="Loan Forgiveness">
            <SidebarItem
              @route="authenticated.pslf.dashboard"
              @label="PSLF"
              data-test-sidebar-pslf
            />
            <SidebarItem
              @route="authenticated.pslf.my-documents"
              @label="My Forms and Documents"
              data-test-sidebar-employee-pslf-my-documents
            />
          </SidebarItemGroup>
        {{/if}}
        {{#if @showTuitionAssistance}}
          <SidebarItem
            @route="authenticated.tas.dashboard"
            @icon={{AcademicCap}}
            @label="Tuition Assistance"
            data-test-sidebar-employee-primary-tuition-assistance
          />
        {{/if}}
        {{#if @showSyf}}
          <SidebarItem
            @route="authenticated.syf.dashboard"
            @icon={{RocketLaunch}}
            @label="Secure Your Future"
            data-test-sidebar-employee-primary-secure-your-future
          />
        {{/if}}
        {{#if @showIdr}}
          <SidebarItem
            @icon={{CurrencyDollar}}
            @route="authenticated.idr.dashboard"
            @label="Income-Driven Repayment"
            data-test-sidebar-employee-primary-income-driven-repayment
          />
        {{/if}}

        <SidebarItemGroup
          @icon={{CurrencyDollar}}
          @label="Repaying Debt"
          data-test-sidebar-employee-primary-repaying-student-debt
        >
          <SidebarItem @route="authenticated.repaying-student-debt.askjeni" @label="Loan Coaches" />
          <SidebarItem
            @route="authenticated.repaying-student-debt.loan-forgiveness.index"
            @label="Loan Forgiveness"
          />
          <SidebarItem
            @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard"
            @label="Strategy Finder"
          />
          <SidebarItem
            @route="authenticated.repaying-student-debt.repayment-options"
            @label="Consolidation & Refinancing"
          />
        </SidebarItemGroup>
        <SidebarItemGroup @icon={{BookOpen}} @label="Questions">
          <SidebarItem
            @route="authenticated.questions-you-have.paying-for-college"
            @label="Types of Student Loans"
          />
          <SidebarItem
            @route="authenticated.questions-you-have.repayment-plans"
            @label="Repayment Plans"
          />
          <SidebarItem @route="authenticated.questions-you-have.glossary" @label="Loan Glossary" />
        </SidebarItemGroup>
      {{/if}}
    </ul>
  </li>
  <li class="mt-auto">
    <ul>
      {{! Shows pinning to bottom of sidebar }}
      <SidebarItem class="mt-auto" @route="logout" @icon={{ArrowLeftOnRectangle}} @label="Logout" />
    </ul>
  </li>
</template>;
